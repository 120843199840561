import {useState} from "react";
import CategoriesList from "../CategoriesList";
import DisabledMenu from "../DisabledMenu";
import Footer from "../Footer";
import Header from "../Header";
import MenuList from "../MenuList";

function Menu({logo, menuContent, disabled }) {
    const menu = menuContent && menuContent.filter((category) => category.id !== "0");
    const [activeCategoryId, setActiveCategoryId] = useState(null);

    return (
        <>
            {disabled ? (<DisabledMenu text="Наше меню пока недоступно для просмотра, мы совершенствуем его в
                            данный момент, пожалуйста, попробуйте еще раз чуть позже."/>) :
                (
                    <div className="menu-container">
                        {logo && <Header logo={logo}/>}
                        {(menu?.length) &&
                        <main className="main">
                            <CategoriesList
                                categories={menu}
                                activeCategory={activeCategoryId}
                                selectActiveCategory={setActiveCategoryId}
                            />
                            <MenuList menu={menu} activeCategory={activeCategoryId}/>
                        </main>
                        }
                        <Footer/>
                    </div>
                )}
        </>
    );
}

export default Menu;
