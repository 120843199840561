import {useEffect, useRef} from "react";

const scrollToCategoryContent = (menuCategory) => {
    // TODO need to check later when we add header if headerOffset static value will be enough for proper scroll
    // const headerOffset = categoryId === isHome ? 80 : 52;
    const headerOffset = 80;
    const elementPosition = menuCategory.current.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
    });
}

function CategoryMenu({categoryId, categoryName, isActive, isHome, isFirstCategory, children}) {
    const menuCategory = useRef(null);
    const classes = `menu__category ${isHome ? ' menu__category--home' : ''}`;

    useEffect(() => {
        if (isActive) {
            scrollToCategoryContent(menuCategory);
        }
    }, [isActive, categoryId, isFirstCategory])

    return (
        <div className={classes} id={categoryId} ref={menuCategory}>
            <h3 className="menu__category__name">{categoryName}</h3>
            <>{children}</>
        </div>
    )
}

export default CategoryMenu;
