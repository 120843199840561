import React from "react";
import disabledMenuPerson from "../../assets/images/disabled-menu-person.png";
import './disabledList.css';
function DisabledMenu({text}) {
    return (
        <div className="disabled-menu">
            <div className="disabled-menu__container">
                <div className="disabled-menu__content">
                    <img src={disabledMenuPerson} alt="" className="disabled-menu__image" />
                    <h1 className="disabled-menu__title">Ууупсс...</h1>
                    <div className="disabled-menu__text">
                        <p>{text}</p>
                    </div>
                </div>
                <div className="disabled-menu__footer">
                    <p>Создано на <a href="https://orry.online">orry.online</a></p>
                </div>
            </div>
        </div>
    );
}

export default DisabledMenu;
