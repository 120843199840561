import React from "react";
import DishItem from "../DishItem";

function DishesList({dishes, expandedDishId, setExpandedDishId}) {
    const dishesListItems = dishes && dishes.map((dish) => {
        const isExpanded = expandedDishId === dish.id;

        return (
           <DishItem key={dish.id} dish={dish} isDishExpanded={isExpanded} onClick={setExpandedDishId}/>
        )
    });

    return (
        <div className="dish__list">
            {dishesListItems}
        </div>
    );
}

export default DishesList;

