function DishItem({dish, isDishExpanded, onClick}) {
    const {id, name, image, ingredients, price, weight} = dish;
    const dishClassList = `dish ${isDishExpanded ? 'dish--expand' : ''}`

    const ellipsis = (dishName) => {
        const maxCharInContent = 60;
        const partToShow = dishName.substring(0, maxCharInContent);
        const partToHide = dishName.substring(maxCharInContent, dishName.length);

        return (
            <>
                {partToShow}
                <span className="dish__name--overflow">{partToHide}</span>
                <span className="dish__name__ellipsis dish__name__ellipsis--show">...</span>
            </>
        );
    }

    return (
        <div className={dishClassList} id={`dish-${id}`} key={id} onClick={() => onClick(id)}>
            {
                !!image &&
                <div className="dish__img__wrapper">
                    <div className="dish__img">
                        <img src={image} alt="Dish Image"/>
                    </div>
                </div>
            }
            <div className="dish__info">
                <p className="dish__name">{name.length > 60 ? ellipsis(name): name}</p>
                <p className="dish__description">{ingredients}</p>
                <div className="dish__info__specification">
                    <div className="dish__price price">
                        <span>{price} ₴</span>
                    </div>
                    <span className="dish__weight">{weight} г</span>
                    {/*TODO add calories info*/}
                </div>
            </div>
        </div>
    )
}

export default DishItem;
