function Loading({logo}) {
    return (
        <div className="menu-container ">
            <div className="menu-content menu-content__loading">
                {logo && <img src={logo} className='logo-img logo-img__loading' alt="Логотип" />}
            </div>
        </div>
    );
}

export default Loading;
