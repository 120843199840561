import CategoryMenu from "../CategoryMenu";
import DishesList from "../DishesList";
import {useState} from "react";

function MenuList({menu, activeCategory}) {
    const [expandedDishId, setExpandedDishId] = useState(null);

    const dishesListItems = menu.map((category, index) => {
        const {id, name, home, dishes} = category;
        const isCategoryActive = activeCategory === id;
        const isFirstCategory = index === 0;

        const setExpandableDish = (dishId) => {
            setExpandedDishId(dishId === expandedDishId ? null : dishId);
        }

        return (
            <CategoryMenu
                categoryId={id}
                key={`cat-menu-${id}`}
                categoryName={name}
                isActive={isCategoryActive}
                isHome={home}
                isFirstCategory={isFirstCategory}
            >
                <DishesList
                    expandedDishId={expandedDishId}
                    setExpandedDishId={setExpandableDish}
                    key={`dish-${id}`}
                    dishes={dishes}
                    categoryId={id}
                />
            </CategoryMenu>
        )
    });
   return (
       <section className="menu">
           {dishesListItems}
       </section>
   )
}

export default MenuList;
