import React, {useState, useEffect, useRef} from "react";
import CategoryItem from "../CategoryItem/CategoryItem";
import Sticky from "../Sticky";

const getHomeCategoryId = (categories) => {
    const homeCategory = categories.find(cat => cat.home);
    return  !!homeCategory ? homeCategory.id : categories[0].id
};

function CategoriesList({categories, activeCategory, selectActiveCategory}) {
    const [homeCategory, setHomeCategory] = useState(null);
    const scrollableContainer = useRef(null);

    useEffect(() => {
        if (categories && activeCategory === null) {
            setHomeCategory(getHomeCategoryId(categories));
        }
        if(activeCategory) {
            setHomeCategory(null);
        }
    }, [categories, activeCategory]);

    const categoriesListItems = categories
        .map((category) => {
            const {id, name, home} = category;
            const isCategoryActive = activeCategory === id || homeCategory === id;

            return <CategoryItem
               key={id}
               id={id}
               name={name}
               active={isCategoryActive}
               scrollable={scrollableContainer}
               isHome={home}
               onClick={() => selectActiveCategory(id)}/>
        });

    return (
        <Sticky
            position="top"
            stuckClasses="categories__wrap categories__wrap--shadow-on"
            unstuckClasses="categories__wrap"
        >
            <div className="categories" ref={scrollableContainer}>
                {categoriesListItems}
            </div>
        </Sticky>
    );
}

export default CategoriesList;
