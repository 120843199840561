function Header({logo}) {
    return (
        <div className="header__wrapper">
            <header className="header">
                <div className="logo">
                    <img src={logo} alt="Restaurant logo"/>
                </div>
                <div className="header__navigation header__navigation--grey">
                   {/*TODO add language picker and burger menu*/}
                </div>
            </header>
        </div>
    );
}



export default Header;
