import React, {useEffect, useRef} from "react";

const moveCategoryItemIntoView = (currentCategoryItem, scrollable) => {
    const categoriesParent = document.querySelector('.categories');
    const { left: categoryLeft, right: categoryRight } = currentCategoryItem.getBoundingClientRect();
    const { right: wrapperRight, left: wrapperLeft } = scrollable.getBoundingClientRect();
    const sidePadding = 16;
    const gapBetween = 12;
    const nextCategoryItemPart = 20;
    const rightSideEnd = categoryRight + sidePadding;
    const leftSideEnd = categoryLeft - sidePadding;

    if (rightSideEnd >= wrapperRight) {
        const positionToScroll = rightSideEnd - wrapperRight + gapBetween + nextCategoryItemPart;
        categoriesParent.scrollLeft += positionToScroll;
    }

    if (leftSideEnd < wrapperLeft) {
        const sizeBehindScreen = !!wrapperLeft ? wrapperLeft - Math.abs(leftSideEnd) : Math.abs(leftSideEnd);
        const positionToScroll = Math.abs(sizeBehindScreen) + gapBetween + nextCategoryItemPart;
        categoriesParent.scrollLeft -= Math.abs(positionToScroll);
    }
};

function CategoryItem({name, id, active, isHome, scrollable, onClick}) {
    const currentTab = useRef(null);
    const classes = `btn-main category ${active ? 'category--active' : ''} ${isHome ? ' category--home' : ''}`;

    useEffect(() => {
        if(active) {
            moveCategoryItemIntoView(currentTab.current, scrollable.current)
        }
    }, [active, scrollable])
    return (
        <>
            {id !== 0 &&
            <button
                className={classes}
                ref={currentTab}
                data-cat={id}
                onClick={()=>{onClick(id)}}
            >{name}</button>}
        </>
    )
}

export default CategoryItem;
