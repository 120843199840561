import '../../css/footer.css';
import orryLogo from '../../assets/images/svg/orry-logo.svg';

function Footer() {
    return (
        <footer className="footer">
            <span className="footer__text">Створено на</span>
            <div className="footer__img">
                <img src={orryLogo} alt="Orry Logo"/>
            </div>
        </footer>
    )
}

export default Footer;
