import React, {useEffect, useState} from "react";

function Sticky({position, stuckClasses, unstuckClasses, children}) {
    const [stuck, setStuck] = useState(false)
    const ref = React.createRef()

    const classes = stuck ? stuckClasses : unstuckClasses

    const inlineStyles = {
        position: "sticky",
        [position]: -1
    }

    useEffect(() => {
        const cachedRef = ref.current
        const observer = new IntersectionObserver(
            ([entry]) => {
                setStuck(entry.intersectionRatio < 1)
            },
            { threshold: [1] }
        )
        observer.observe(cachedRef)
        return () => observer.unobserve(cachedRef)
    }, [ref])

    return (
        <div style={inlineStyles} className={classes} ref={ref}>
            {children}
        </div>
    )
}

export default Sticky;
