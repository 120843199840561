import React from 'react';
import './css/general.css';
import MainContainer from "./components/MainContainer";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";

function App() {
  return (
      <div className="App">
          <Router>
              <Routes>
                  <Route path="/:restaurantId/:menuId"  element={<MainContainer />} />
              </Routes>
          </Router>
      </div>
  );
}

export default App;
